import React, {useContext, useEffect} from 'react';
import "./Dubai.scss"
import Back from '../../components/back-button/Back';
import VisibilitySelect from '../../components/visibility-select/VisibilitySelect';
import ZoomButton from '../../components/zoom-button/ZoomButton';
import TestBtn from '../../components/test-btn/TestBtn';
import Clouds from '../../components/clouds/Clouds';
import EmiratesHills from "../../../src/static/img/test/Group 62.png";
import MBRCity from "../../../src/static/img/test/Component 1.svg";
import Compass from '../../components/compass/Compass';
import { MainContext } from '../../App';
import { useNavigate } from 'react-router-dom';

import DubaiImage from "../../static/img/Dubai_map.png"

//SVG's
import marker from "../../static/img/MARKER.svg"
import pin from "../../static/img/PIN.svg"

//JSON
import communities from "../../data/dubai/communities.json"
import landmarks from "../../data/dubai/landmarks.json"
import malls from "../../data/dubai/malls.json"
import schools from "../../data/dubai/schools.json"
import hospitals from "../../data/dubai/hospitals.json"

export default function Dubai(){

  const [
    selectComActive,
    setSelectComActive,
    selectProActive,
    setSelectProActive,
    modal,
    setModal,
    btnActive,
    setBtnActive,
    selectActive,
    setSelectActive,
    communitiesMarkers,
    setCommunitiesMarkers,
    propertiesMarkers,
    setPropertiesMarkers,
    selectedItems,
    setSelectedItems,
    markers,
    setMarkers,
  ] = useContext(MainContext);

  const navigate = useNavigate();

  const markerData = {
    landmarks: landmarks,
    malls: malls,
    schools: schools,
    hospitals: hospitals,
  };

  useEffect(() => {
    const activeMarkers = selectedItems.flatMap((item) => markerData[item] || []);
    setMarkers(activeMarkers);
  }, [selectedItems]);
  
  

  return (
    <>
      <div
       className="dubai-wrapper"
      >
        <img className='map-image' src={DubaiImage} />
        <Clouds/>
        <div className="btns">
          <Back
           link={"/"}
          />
          <VisibilitySelect/>
          <ZoomButton link={"/dubai-zoomed"}/>
          <Compass/>
        </div>
        <div className="svg-wrapper">
          {markers.length > 0 && (
            <svg viewBox="0 0 6000 3375">
              {markers.map((item, index) => (
                <g key={index} className="marker-group" onClick={() => navigate(item.path)}>
                  <image
                    x={item.cords[0]}
                    y={item.cords[1]}
                    href={marker}
                    className='image-pins'
                  />
                  <text
                    x={item.cords[0] - 130}
                    y={item.cords[1] + 140}
                    className="marker-text"
                  >
                    {item.name}
                  </text>
                </g>
              ))}
            </svg>
          )}
          {communitiesMarkers ? 
            <>
              <svg
                viewBox="0 0 6000 3375"
              >
                {communities.map((item, index) => (
                  <g key={index} className="marker-group" onClick={() => navigate(item.path)}>
                    <polygon
                      points={item.cords_of_polygon.map(coord => coord.join(',')).join(' ')}
                      strokeWidth={2}
                      className="polygon"
                    />
                    <image
                      x={item.cords[0]}
                      y={item.cords[1]}
                      href={pin}
                      className='image-pins'
                    />
                    <text
                      x={item.cords[0] - 130}
                      y={item.cords[1] + 140}
                      className="marker-text"
                    >
                      {item.name}
                    </text>
                  </g>
                ))}
              </svg>
            </>
            :
            <>
            </>
          }
        </div>
      </div>
    </>
  );
};
