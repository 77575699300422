import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import "./ZoomButton.scss"
import ZoomLoop from "../../static/img/Union.svg"
import {MainContext} from "../../App";

export default function ZoomButton({link}){

  const [
    selectComActive,
    setSelectComActive,
    selectProActive,
    setSelectProActive,
    modal,
    setModal,
    btnActive,
    setBtnActive,
    selectActive,
    setSelectActive,
  ] = useContext(MainContext);
  
  return (
    <>
      <Link
       to={link} 
       className="zoom-button"
       onClick={() => {
        setSelectComActive(false);
        setSelectProActive(false);
        setSelectActive(false);
       }}
      >
        <img src={ZoomLoop} alt="zoom-loop" />
        <span>zoom</span>
      </Link>
    </>
  );
};
