import React from 'react';
import "./Start.scss";
import StartButton from '../../components/start-button/StartButton';
import Compass from '../../components/compass/Compass';

export default function Start(){

  return (
    <>
      <div className="start-wrapper">
        <div className="button-con">
            <StartButton
             title={"abu dhabi"} 
             link={"/abu-dhabi"}
            />
            <hr />
            <StartButton
             title={"dubai"} 
             link={"/dubai"}
            />
            <hr />
            <StartButton
             title={"ras al khaimah"} 
             link={"/ras-al-khaimah"}
            />
            <Compass />
        </div>
      </div>
    </>
  );
};
