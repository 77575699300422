import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './DubaiZoomed.scss';
import Back from '../../components/back-button/Back';
import VisibilitySelect from '../../components/visibility-select/VisibilitySelect';
import UnzoomButton from '../../components/unzoom-button/UnzoomButton';
import TestBtn from '../../components/test-btn/TestBtn';
import EmiratesHills from "../../../src/static/img/test/Group 62.png";
import MercerHouseImg from "../../static/img/test/Frame 33.png"
import MBRCity from "../../../src/static/img/test/Component 1.svg";
import Compass from '../../components/compass/Compass';
import { MainContext } from '../../App';

//SVG's
import marker from "../../static/img/MARKER.svg"
import pin from "../../static/img/PIN.svg"

//JSON
import properties from "../../data/dubai/properties.json"
import communities from "../../data/dubai/communities.json"
import landmarks from "../../data/dubai/landmarks.json"
import malls from "../../data/dubai/malls.json"
import schools from "../../data/dubai/schools.json"
import hospitals from "../../data/dubai/hospitals.json"

import DubaiZoomedImage from "../../static/img/Dubai_map_zoomed.png"

export default function DubaiZoomed(){
  const navigate = useNavigate();

  const [
    selectComActive,
    setSelectComActive,
    selectProActive,
    setSelectProActive,
    modal,
    setModal,
    btnActive,
    setBtnActive,
    selectActive,
    setSelectActive,
    communitiesMarkers,
    setCommunitiesMarkers,
    propertiesMarkers,
    setPropertiesMarkers,
    selectedItems,
    setSelectedItems,
    markers,
    setMarkers,
  ] = useContext(MainContext);

  const markerData = {
    landmarks: landmarks,
    malls: malls,
    schools: schools,
    hospitals: hospitals,
  };

  useEffect(() => {
    const activeMarkers = selectedItems.flatMap((item) => markerData[item] || []);
    setMarkers(activeMarkers);
  }, [selectedItems]);

  return (
    <>
        <div className="dubai-zoomed-wrapper">
          <img className='map-image' src={DubaiZoomedImage} />
          <div className="btns">
            <Back
              link={"/dubai"}
            />
            <VisibilitySelect/>
            <UnzoomButton
              link={"/dubai"}
            />
            <Compass/>
          </div>
          <div className="svg-wrapper">
            {markers.length > 0 && (
              <svg viewBox="0 0 3970 2178">
                {markers.map((item, index) => (
                  <g key={index} className="marker-group" onClick={() => navigate(item.path)}>
                    <image
                      x={item.cords[0]}
                      y={item.cords[1]}
                      href={marker}
                      className='image-pins'
                    />
                    <text
                      x={item.cords[0] - 130}
                      y={item.cords[1] + 140}
                      className="marker-text"
                    >
                      {item.name}
                    </text>
                  </g>
                ))}
              </svg>
            )}
            {propertiesMarkers ? 
              <>
                <svg
                  viewBox="0 0 3970 2178"
                >
                  {properties.map((item, index) => (
                    <>
                      <g key={index} className="marker-group" onClick={() => navigate(item.path)}>
                        <image
                          x={item.cords[0]}
                          y={item.cords[1]}
                          href={pin}
                          className='image-pins'
                        />
                        <text
                          x={item.cords[0] - 130}
                          y={item.cords[1] + 100}
                          className="marker-text"
                        >
                          {item.name}
                        </text>
                      </g>
                    </>
                  ))}
                </svg>
              </>
              :
              <>
                <svg
                  viewBox="0 0 3970 2178"
                >
                  {communities.map((item, index) => (
                    <>
                      <g key={index} className="marker-group" onClick={() => navigate(item.path)}>
                        <polygon
                          points={item.cords_of_polygon2.map(coord => coord.join(',')).join(' ')}
                          strokeWidth={2}
                          className="polygon"
                        />
                        <image
                          x={item.cords2[0]}
                          y={item.cords2[1]}
                          href={pin}
                          className='image-pins'
                        />
                        <text
                          x={item.cords2[0] - 130}
                          y={item.cords2[1] + 140}
                          className="marker-text"
                        >
                          {item.name}
                        </text>
                      </g>
                    </>
                  ))}
                </svg>
              </>
            }
          </div>
        </div>
    </>
  );
};
