import React, {createContext, useState} from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Start from "./pages/start/Start"
import Dubai from "./pages/dubai/Dubai"
import Header from "./components/header/Header";
import HeaderEmptyVoid from "./components/header-empty-void/HeaderEmptyVoid";

import PageTransition from "./animations/PageTransition";
import { AnimatePresence } from "framer-motion";
import DubaiZoomed from "./pages/dubai-zoomed/DubaiZoomed";
import CommunityPage from "./pages/communities/community-page/CommunityPage";
import PropertyPage from "./pages/communities/community-page/property-page/PropertyPage";
import AbuDhabi from "./pages/abu-dhabi/AbuDhabi";
import RasAlKhaimah from "./pages/ras-al-khaimah/RasAlKhaimah";
import GalleryOfProperty from "./pages/gallery-of-property/GalleryOfProperty";
import PropertyFloorPlans from "./pages/property-floor-plans/PropertyFloorPlans";
import PropertyVideos from "./pages/videos-of-property/PropertyVideos";
import Error from "./pages/error-page/Error";

function AnimatedRoutes() {

    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes
             location={location} 
             key={location.pathname}
            >
                <Route
                    path="/" 
                    element={
                        <PageTransition>
                            <Start />
                        </PageTransition>
                    }
                />
                <Route
                    path="/dubai" 
                    element={
                        <PageTransition>
                            <Dubai />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/abu-dhabi" 
                    element={
                        <PageTransition>
                            <AbuDhabi />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/ras-al-khaimah" 
                    element={
                        <PageTransition>
                            <RasAlKhaimah />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/dubai-zoomed" 
                    element={
                        <PageTransition>
                            <DubaiZoomed />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/dubai/emirates-hills" 
                    element={
                        <PageTransition>
                            <CommunityPage />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/dubai/emirates-hills/mercer-house" 
                    element={
                        <PageTransition>
                            <PropertyPage />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/dubai/emirates-hills/mercer-house/gallery" 
                    element={
                        <PageTransition>
                            <GalleryOfProperty />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/dubai/emirates-hills/mercer-house/videos" 
                    element={
                        <PageTransition>
                            <PropertyVideos />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/dubai/emirates-hills/mercer-house/floor-plans" 
                    element={
                        <PageTransition>
                            <PropertyFloorPlans />
                        </PageTransition>
                    } 
                />
                <Route
                    path="*"
                    element={<Error />}
                />
            </Routes>
        </AnimatePresence>
    );
}

export const MainContext = createContext(null);

export default function AppRouter() {

    const [selectComActive, setSelectComActive] = useState(false);
    const [selectProActive, setSelectProActive] = useState(false);
    const [modal, setModal] = useState(false);
    const [btnActive, setBtnActive] = useState(false);
    const [selectActive, setSelectActive] = useState(false);
    const [communitiesMarkers, setCommunitiesMarkers] = useState(true);
    const [propertiesMarkers, setPropertiesMarkers] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [markers, setMarkers] = useState([]);

    return (
        <Router>
            <MainContext.Provider
                value={[
                    selectComActive,
                    setSelectComActive,
                    selectProActive,
                    setSelectProActive,
                    modal,
                    setModal,
                    btnActive,
                    setBtnActive,
                    selectActive,
                    setSelectActive,
                    communitiesMarkers,
                    setCommunitiesMarkers,
                    propertiesMarkers,
                    setPropertiesMarkers,
                    selectedItems,
                    setSelectedItems,
                    markers,
                    setMarkers,
                ]}
            >
                <Header />
                <HeaderEmptyVoid />
                <AnimatedRoutes />
            </MainContext.Provider>
        </Router>
    );
}
