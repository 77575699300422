import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import UnZoomLoop from "../../static/img/Union2.svg"
import {MainContext} from "../../App";

export default function UnzoomButton({link}){

  const [
    selectComActive,
    setSelectComActive,
    selectProActive,
    setSelectProActive,
    modal,
    setModal,
    btnActive,
    setBtnActive,
    selectActive,
    setSelectActive,
    communitiesMarkers,
    setCommunitiesMarkers,
    propertiesMarkers,
    setPropertiesMarkers,
  ] = useContext(MainContext);

  return (
    <>
      <Link
       to={link} 
       className="zoom-button"
       onClick={() => {
        setSelectComActive(false);
        setSelectProActive(false);
        setSelectActive(false);
        setCommunitiesMarkers(true);
        setPropertiesMarkers(false);
       }}
      >
        <img src={UnZoomLoop} alt="unzoom-loop" />
        <span>zoom</span>
      </Link>
    </>
  );
};
