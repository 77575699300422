import React from 'react';
import "./Clouds.scss"
import Cloud1 from "../../static/img/cloud1.png";
import Cloud2 from "../../static/img/cloud2.png";
import Cloud3 from "../../static/img/cloud3.png";

export default function Clouds(){
  return (
    <>
      <div className="clouds-wrapper">
        <img className='cloud1' src={Cloud1} alt="cloud" />
        <img className='cloud2' src={Cloud2} alt="cloud" />
        <img className='cloud3' src={Cloud3} alt="cloud" />
      </div>
    </>
  );
};
