import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./CommunityPage.scss"
import Back from '../../../components/back-button/Back';
import MercerHouse from '../../../components/test-btn/MercerHouse';
import Compass from '../../../components/compass/Compass';

import properties from "../../../data/dubai/properties.json"

//birds
import EmiratesHillsBird from "../../../static/img/communities_birds/EmiratesHillsBird.png"

export default function EmiratesHills(){

  const navigate = useNavigate();

  return (
    <>
      <div className="emirates-hills-wrapper">
        <img className='map-image' src={EmiratesHillsBird} />
        <Back link={"/dubai"}/>
        <Compass  />
        <div className="svg-wrapper">
          <>
            <svg
              viewBox="0 0 1728 960"
            >
              {properties.map((item, index) => (
                <g key={index} className="marker-group" onClick={() => navigate(item.path)}>
                  <polygon
                    points={item.bird_cords.map(coord => coord.join(',')).join(' ')}
                    strokeWidth={2}
                    className="polygon"
                  />
                  {item.bird_cords > 0 && (
                    <text
                      x={item.bird_cords[0][0]}
                      y={item.bird_cords[0][1]}
                      className="text"
                    >
                      {item.name}
                    </text>
                  )}
                </g>
              ))}
            </svg>
          </>
        </div>
      </div>
    </>
  );
};
