import React, {useContext, useEffect, useRef, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {MainContext} from "../../App";
import "./Header.scss";
import EllingtonLogo from "../../static/img/header_img/ellington_logo.svg"
import loop from "../../static/img/header_img/search_loop.svg"
import html2canvas from 'html2canvas';

import communities from '../../data/dubai/communities.json';
import properties from '../../data/dubai/properties.json';

export default function Header(){

  const comRef = useRef(null);
  const proRef = useRef(null);

  const [
    selectComActive,
    setSelectComActive,
    selectProActive,
    setSelectProActive,
    modal,
    setModal,
    btnActive,
    setBtnActive,
    selectActive,
    setSelectActive,
    communitiesMarkers,
    setCommunitiesMarkers,
    propertiesMarkers,
    setPropertiesMarkers
] = useContext(MainContext);

const [query, setQuery] = useState("");

const navigate = useNavigate();

const handleSearch = (e) => {
  e.preventDefault();
  const allData = [...properties, ...communities];
  const property = allData.find(p => p.name.toLowerCase() === query.toLowerCase());
  if (property) {
    navigate(property.path);
  } else {
    
  }
};

const comMarkersToogle = () => {
  setCommunitiesMarkers(true);
  setPropertiesMarkers(false);
};

const proMarkersToogle = () => {
  setCommunitiesMarkers(false);
  setPropertiesMarkers(true);
};

const handleComMouseEnter = () => {
  setSelectComActive(true);
  setSelectProActive(false);
};

const handleComMouseLeave = () => {
  setSelectComActive(false);
};

const handleProMouseEnter = () => {
  setSelectProActive(true);
  setSelectComActive(false);
};

const handleProMouseLeave = () => {
  setSelectProActive(false);
};

  // const selectComHandleToggle = () => {
  //   setSelectComActive((prev) => !prev);
  //   setSelectProActive(false);
  //   setModal(false);
  //   setBtnActive(false);
  //   setSelectActive(false);
  // }

  // const selectProHandleToggle = () => {
  //   setSelectProActive((prev) => !prev);
  //   setSelectComActive(false);
  //   setModal(false);
  //   setBtnActive(false);
  //   setSelectActive(false);
  // }

  const takeScreenshot = () => {
    html2canvas(document.body).then(canvas => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'screenshot.png';
      link.click();
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comRef.current && !comRef.current.contains(event.target)) {
        setSelectComActive(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [comRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (proRef.current && !proRef.current.contains(event.target)) {
        setSelectProActive(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [proRef]);

  return (
    <>
      <header className="header">
        <Link
          to={"/"} 
          onClick={() => {
            setSelectComActive(false);
            setSelectProActive(false);
            setSelectActive(false);
          }}
        >
          <img
            className='logo' 
            src={EllingtonLogo} 
            alt="ellington-image" 
          />
        </Link>
        <div className="span-con">
          <div
           className="span-select" 
           style={{textAlign: 'right'}} 
           ref={comRef}
           onMouseEnter={handleComMouseEnter} 
           onMouseLeave={handleComMouseLeave}
          >
            <span
              className='span'
              // onClick={selectComHandleToggle}
              onClick={comMarkersToogle}
              style={{fontWeight: communitiesMarkers ? "600" : "500"}}
            >
              our communities
            </span>
            {selectComActive && (
              <ul className={`header-select-bottom${selectComActive ? " activeCom" : ""}`}>
                {communities.map((item, index) => (
                  <Link to={item.path} key={index}>
                    <li key={index}>{item.name}</li>
                  </Link>
                ))}
              </ul> 
              )
            }
          </div>
          <span className="separator">|</span>
          <div
           className="span-select" 
           style={{textAlign: 'left'}} 
           ref={proRef}
           onMouseEnter={handleProMouseEnter} 
           onMouseLeave={handleProMouseLeave}
          >
            <Link
              className='span'
              // onClick={selectProHandleToggle}
              onClick={proMarkersToogle}
              style={{fontWeight: propertiesMarkers ? "600" : "500"}}
              to={"/dubai-zoomed"}
            >
              our properties
            </Link>
            {selectProActive && (
              <ul
               className={`header-select-bottom${selectProActive ? " activePro" : ""}`}
              >
                {properties.map((item, index) => (
                  <Link to={item.path} key={index}>
                    <li style={{fontWeight: item.name === "View All Properties" ? "700" : ""}} key={index}>{item.name}</li>
                  </Link>
                ))}
              </ul>
              )
            }
          </div>
        </div>
        <div className="con-search">
          <button
            onClick={takeScreenshot}
            className='screenShot-btn'
          >
          </button>
          <form className='search-form' onSubmit={handleSearch}>
            <button type='submit' className='search-btn'>
              <img
                className='loop' 
                src={loop} 
                alt="loop" 
              />
            </button>
            <input
              placeholder="SEARCH" 
              type="text" 
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </form>
        </div>
      </header>
    </>
  );
};
