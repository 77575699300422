import React, {useContext} from 'react';
import "./Back.scss"
import { Link } from 'react-router-dom';
import {MainContext} from "../../App";

export default function Back({link}) {

  const [
    selectComActive,
    setSelectComActive,
    selectProActive,
    setSelectProActive,
    modal,
    setModal,
    btnActive,
    setBtnActive,
    selectActive,
    setSelectActive,
    communitiesMarkers,
    setCommunitiesMarkers,
    propertiesMarkers,
    setPropertiesMarkers
  ] = useContext(MainContext);

  return (
    <>
      <Link
       className='back-btn' 
       to={link}
       onClick={() => {
        setSelectComActive(false);
        setSelectProActive(false);
        setSelectActive(false);
        setCommunitiesMarkers(true);
        setPropertiesMarkers(false);
       }}
      >
        <span>🡠 back</span>
      </Link>
    </>
  );
};
