import React, {useContext, useEffect, useRef} from 'react';
import "./PropertyPage.scss"
import Back from '../../../../components/back-button/Back';
import img1 from "../../../../static/img/test/image1.png"
import img2 from "../../../../static/img/test/image2.png"
import img3 from "../../../../static/img/test/image3.png"
import { Link, useLocation } from 'react-router-dom';
import { MainContext } from '../../../../App';
import Crusade from "../../../../static/img/test/Close.png"

//logos
import MercerHouseLogo from "../../../../static/img/test/ye4rt.svg"

//birds
import MercerHouseBird from "../../../../static/img/properties_birds/MercerHouseBird.png"


import Compass from '../../../../components/compass/Compass';

import properties from '../../../../data/dubai/properties.json';

export function CloseBtn({func}){
    return (
      <>
        <img onClick={func} className='close-btn-img' src={Crusade} alt="" />
      </>
    );
};
  

export default function MercerHousePage(){

  const logos = {
    "MercerHouseLogo": MercerHouseLogo,
  };

  const birds = {
    "MercerHouseBird": MercerHouseBird,
  };

  const menuRef = useRef(null);
  const location = useLocation();

  const [
      selectComActive,
      setSelectComActive,
      selectProActive,
      setSelectProActive,
      modal,
      setModal,
  ] = useContext(MainContext);

  const toggleModal = () => {
      setModal(!modal);
      setSelectComActive(false);
      setSelectProActive(false);
  }

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setModal(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [menuRef]);

  const close = () => {
      setModal(false);
  }

  const getDescription = () => {
    const currentPath = location.pathname;
    const property = properties.find(item => item.path === currentPath);
    return property ? property.desc : 'description is not found';
  };

  const getModalTitle = () => {
    const currentPath = location.pathname;
    const property = properties.find(item => item.path === currentPath);
    return property ? property.short_desc : 'modal title is not found';
  };

  const getLink = () => {
    const currentPath = location.pathname;
    const property = properties.find(item => item.path === currentPath);
    return property ? property.link : 'link is not found';
  }

  const getFormattedLink = () => {
    const link = getLink();
    return link.replace(/^https?:\/\/(www\.)?/, '');
  };

  const getPropertyLogo = () => {
    const currentPath = location.pathname;
    const property = properties.find(item => item.path === currentPath);
    return property ? logos[property.logo] : 'logo is not found';
  };

  return (
    <>
      <div className="mercer-house-page-wrapper" >
        <img className='map-image' src={MercerHouseBird} />
        <Back link={"/dubai/emirates-hills"}/>
        <div className="svg-wrapper">
          <>
            <svg
              viewBox="0 0 1202 667"
            >
              {properties.map((item, index) => (
                <g key={index} className="marker-group" onClick={toggleModal}>
                  <polygon
                    points={item.property_bird_cords.map(coord => coord.join(',')).join(' ')}
                    strokeWidth={2}
                    className="polygon"
                  />
                  {item.property_bird_cords > 0 && (
                    <text
                      x={item.property_bird_cords[0][0]}
                      y={item.property_bird_cords[0][1]}
                      className="text"
                    >
                      {item.name}
                    </text>
                  )}
                </g>
              ))}
            </svg>
          </>
        </div>
        {modal && (
          <>
            <div className="modal-window" ref={menuRef}>
                <CloseBtn func={close}/>
                <img className='modal-window-property-logo' src={getPropertyLogo()} alt="" />
                <span className='modal-title'>{getModalTitle()}</span>
                <span className='modal-dcs'>{getDescription()}</span>
                <div className="modal-window-main-btns">
                    <Link to={"/dubai/emirates-hills/mercer-house/gallery"} className="modal-window-btn">
                        <img src={img1} alt="s" />
                        <span>gallery</span>
                    </Link>
                    <Link to={"/dubai/emirates-hills/mercer-house/videos"} className="modal-window-btn">
                        <img src={img2} alt="a" />
                        <span>videos</span>
                    </Link>
                    <Link to={"/dubai/emirates-hills/mercer-house/floor-plans"} className="modal-window-btn">
                        <img src={img3} alt="s" />
                        <span>floor plans</span>
                    </Link>
                </div>
                <div className="modal-window-main-btns2">
                    <div className="modal-window-btn2">
                        <span>documents</span>
                    </div>
                    <div className="modal-window-btn2">
                        <span>others</span>
                    </div>
                </div>
                <a target='_blank' className='web-link' href={getLink()}>{getFormattedLink()}</a>
            </div>
          </>
        )}
        <Compass  />
      </div>
    </>
  );
};
