import React, { useContext } from 'react';
import './StartButton.scss'
import { Link } from 'react-router-dom';
import {MainContext} from "../../App";

export default function StartButton({title, link}){

  const [
      selectComActive,
      setSelectComActive,
      selectProActive,
      setSelectProActive,
  ] = useContext(MainContext);

  return (
    <>
      <Link
       to={link} 
       className="start-button-wrapper"
       onClick={() => {
        setSelectComActive(false);
        setSelectProActive(false);
       }}
      >
        <span>{title}</span>
      </Link>
    </>
  );
};
