import React from 'react';
import "./AbuDhabi.scss"

export default function AbuDhabi(){
  return (
    <>
      <span className='span-unawailable'>This city is unavailable now</span>
      <div className="abu-dhabi-wrapper">

      </div>
    </>
  );
};
