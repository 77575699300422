import React, { useState, useEffect, useContext, useRef } from 'react';
import "./VisibilitySelect.scss";
import SelectArrow from "../../static/img/Arrow 2.svg";
import Switch from "react-switch";
import { MainContext } from '../../App';

export default function VisibilitySelect() {
  const [
    selectComActive,
    setSelectComActive,
    selectProActive,
    setSelectProActive,
    modal,
    setModal,
    btnActive,
    setBtnActive,
    selectActive,
    setSelectActive,
    communitiesMarkers,
    setCommunitiesMarkers,
    propertiesMarkers,
    setPropertiesMarkers,
    selectedItems,
    setSelectedItems,
  ] = useContext(MainContext);

  const [showDistances, setShowDistances] = useState(false);
  const menuRef = useRef(null);

  const array = [
    'landmarks', 
    'malls', 
    'schools', 
    'hospitals'
  ];

  const selectHandleToggle = () => {
    setSelectActive((prev) => !prev);
    setSelectComActive(false);
    setSelectProActive(false);
    setBtnActive(false);
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems((prev) =>
      prev.includes(item)
        ? prev.filter((i) => i !== item)
        : [...prev, item]
    );
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      setShowDistances(false);
    }
  }, [selectedItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setSelectActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      <div className="select-con" ref={menuRef}>
        <div onClick={selectHandleToggle} className={`select${selectActive ? " active" : ""}`}>
          <img src={SelectArrow} alt="top-arrow" />
          <span>visibility</span>
        </div>
        {selectActive ? 
          <>
            <div className="select-bottom">
              {array.map((item) => 
                <label
                  key={item} 
                  className="form-control"
                >
                  <input
                    type="checkbox" 
                    name="checkbox" 
                    checked={selectedItems.includes(item)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <span>{item}</span>
                </label>
              )}
            </div>
            {selectedItems.includes('landmarks') && (
              <div className="switch-con">
                <label className='switch-label'>
                  <Switch
                      className="header-switch-element"
                      onChange={() => {setShowDistances((prev) => !prev)}}
                      checked={showDistances}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#888"
                      offColor="#888"
                      onHandleColor="#99FF00"
                      offHandleColor="#FFF"
                      handleDiameter={12}
                      height={16}
                      width={30}
                  />
                  <span 
                   onChange={() => {setShowDistances((prev) => !prev)}}
                  >show distances</span>
                </label>
              </div>
            )}
          </>
          :
          <></>
        }
      </div>
    </>
  );
};
