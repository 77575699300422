import React from 'react';
import './PropertyFloorPlans.scss';

import FloorPlan from "../../static/img/test/floor_plan.png"
import CrusadeBtn from '../../components/crusade/CrusadeBtn';

import Download from "../../static/img/test/download_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.png"

export default function PropertyFloorPlans(){
  return (
    <>
      <div className="floor-plans-container">
        <CrusadeBtn />
        <button className='download-btn'>
          <img src={Download} alt="" />
          <span>Download pdf</span>
        </button>
        <img className='plan-img' src={FloorPlan} alt="Floor Plan" />
      </div>
    </>
  );
};
