import React from 'react';
import './Crusade.scss';
import { Link } from 'react-router-dom';
import Crusade from "../../static/img/test/Close.png"

export default function CrusadeBtn(){
  return (
    <>
      <Link to={"/dubai/emirates-hills/mercer-house/"} className="crusade-btn">
        <img src={Crusade} alt="" />
      </Link>
    </>
  );
};
