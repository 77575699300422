import React from 'react';
import "./RasAlKhaimah.scss"

export default function RasAlKhaimah(){
  return (
    <>
      <span className='span-unawailable'>This city is unavailable now</span>
      <div className="ras-al-khaimah-wrapper">

      </div>
    </>
  );
};
