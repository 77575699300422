import React from 'react';
import "./Error.scss"

export default function Error(){
  return (
    <>
      <div className="empty-page-wrapper">
        <span>404 | This page is unavailable</span>
      </div>
    </>
  );
};
