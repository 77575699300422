import React from 'react';
import './PropertyVideos.scss';

import PropertyLogo from "../../static/img/test/image.svg"
import PropertyImages from "../../static/img/test/Frame 58.png"

import CrusadeBtn from '../../components/crusade/CrusadeBtn';

export default function PropertyVideos(){
  return (
    <>
      <div className="videos-container">
        <img className='property-logo' src={PropertyLogo} alt="property logp" />
        <CrusadeBtn />
        <div className="imgs-wrapper">
            <img src={PropertyImages} alt="test" />
            <img src={PropertyImages} alt="test" />
        </div>
      </div>
    </>
  );
};
